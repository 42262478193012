<template>
  <section>
    <title-section
      title="Slider"
    >
      <button class="button button_micro_dark" @click="openAddSlideModal">AGREGAR SLIDE</button>
    </title-section>
    <web-page-main ref="webPageMain" />
  </section>
</template>

<script>
export default {
  name: 'WebPage',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    WebPageMain: () => import('@/components/WebPage/WebPageMain.vue')
  },
  data () {
    return {
      showModal: false,
      currentSlideData: {}
    }
  },
  methods: {
    openAddSlideModal () {
      this.$refs.webPageMain.openAddSlideModal()
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 15px;
    width: 200px;
  }
  .button_micro_dark_outlined {
    border: solid 2px #262626;
  }
  .ant-btn:hover, .ant-btn:focus {
    color: #000 !important;
    background-color: #fff;
    border-color: #000 !important;
  }
  .pointer {
    cursor: pointer;
  }
</style>
